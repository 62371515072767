import React, { useCallback } from "react"
import { Panel, useReactFlow } from "reactflow"
import Dropdown from "../../dropdown/dropdown"
import { IStageNode, Stage } from "../../../../utils/types"
import { useWorkflow } from "./workflowContext"
import { RequestStageIcon } from "./requestStageIcon"
import { getFirstTaskOfStageCoordinates } from "./graphHelper"

interface StageNavigationProps {
  stageNodes: IStageNode[]
  taskNodes?: any[] // Make it required when the workflow_simplification_workflow_editing is removed
}

// Remove this when the workflow_simplification_workflow_editing is removed
const LegacyStageNavigation = (props: StageNavigationProps) => {
  const { stageNodes } = props
  const { setViewport, getViewport } = useReactFlow()

  const jumpToStage = useCallback(
    (jumpToViewport) => {
      setViewport(jumpToViewport)
    },
    [setViewport],
  )

  const jumpToPrevStage = useCallback(() => {
    let prevStage = calculatePrevStage()
    if (prevStage) {
      setViewport(prevStage.data.jumpToViewport)
    }
  }, [setViewport])

  const jumpToNextStage = useCallback(() => {
    let nextStage = calculateNextStage()
    if (nextStage) {
      setViewport(nextStage.data.jumpToViewport)
    }
  }, [setViewport])

  const calculatePrevStage = () => {
    // sorts the stage nodes by x position in descending order
    let stageNodesByXPositionDesc = stageNodes.sort((a, b) => b.position.x - a.position.x)
    // returns the first stage node with a jumpToViewport x position that is greater than the current viewport x position
    return stageNodesByXPositionDesc.find((node) => node.data.jumpToViewport.x > getViewport().x)
  }

  const calculateNextStage = () => {
    // sorts the stage nodes by x position in ascending order
    let stageNodesByXPositionAsc = stageNodes.sort((a, b) => a.position.x - b.position.x)
    // returns the first stage node with a jumpToViewport x position that is less than the current viewport x position
    return stageNodesByXPositionAsc.find((node) => node.data.jumpToViewport.x < getViewport().x)
  }

  const sortedStageNodes = stageNodes.sort((a, b) => a.data.order - b.data.order)

  return (
    <Panel position="top-right" className="jump-to-stage-nav">
      <div className="flex flex-row gap-2">
        <button
          className="text-white bg-gray-800 hover:bg-purple-500 active:bg-purple-800 rounded-md shadow-sm px-2 py-1 w-7 h-7 flex items-center justify-center jump-to-prev-stage-button"
          onClick={() => jumpToPrevStage()}
        >
          <i className="fas fa-arrow-left"></i>
        </button>

        <Dropdown
          displayText={`Jump To Stage`}
          contentStyle={{ width: 216 }}
          style={{ minWidth: 120 }}
          className="jump-to-stage-dropdown"
          buttonClassName={"text-white !bg-gray-800 hover:!bg-purple-500 rounded-md shadow-sm pl-2 pr-1 py-1 !h-7"}
          dropdownClassName={
            "border border-gray-300 shadow-lg rounded-md right-0 p-[6px] !bg-white jump-to-stage-dropdown-items"
          }
          caretColor={"white"}
          expandedButtonClassName={"!bg-purple-800"}
        >
          {sortedStageNodes.map((node) => (
            <div
              className="py-[6px] px-[8px] text-gray-800 jump-to-stage-dropdown-item"
              key={node.data.name}
              onClick={() => jumpToStage(node.data.jumpToViewport)}
            >
              {node.data.dropdownName}
            </div>
          ))}
        </Dropdown>

        <button
          className="text-white bg-gray-800 hover:bg-purple-500 active:bg-purple-800 rounded-md shadow-sm px-2 py-1 w-7 h-7 flex items-center justify-center jump-to-next-stage-button"
          onClick={() => jumpToNextStage()}
        >
          <i className="fas fa-arrow-right"></i>
        </button>
      </div>
    </Panel>
  )
}

// Rename this from NewStageNavigation to StageNavigation when the workflow_simplification_workflow_editing is removed
const NewStageNavigation = (props: StageNavigationProps) => {
  const { stageNodes, taskNodes } = props
  const { setViewport, getViewport } = useReactFlow()
  const { setHoveredTaskId } = useWorkflow()

  const jumpToStage = useCallback(
    (name) => {
      const coordinates = getFirstTaskOfStageCoordinates(name, stageNodes, taskNodes)
      if (coordinates.targetTaskNode?.id) {
        setHoveredTaskId(coordinates.targetTaskNode.id)
      }
      setViewport({ ...coordinates, zoom: 1 })
    },
    [setViewport, stageNodes, taskNodes],
  )

  const sortedStageNodes = stageNodes.sort((a, b) => a.data.order - b.data.order)

  return (
    <Panel position="bottom-left" className="jump-to-stage-nav !bottom-[40px]" id="stage-navigation">
      <div className="flex flex-col gap-2 p-4 bg-white border border-gray-200 rounded-lg shadow">
        <div className="small-heading mb-2">Go to Stage</div>
        {sortedStageNodes.map((node) => (
          <button
            className="text-base text-gray-600 flex gap-1 items-center"
            key={node.data.name}
            onClick={() => jumpToStage(node.data.name)}
          >
            <RequestStageIcon stage={node.data.name} />
            <span className="underline cursor-pointer">{node.data.name}</span>
          </button>
        ))}
      </div>
    </Panel>
  )
}

// Remove this when the workflow_simplification_workflow_editing is removed
const StageNavigation = (props: StageNavigationProps) => {
  const { workflowSimplificationWorkflowEditing } = useWorkflow()

  return workflowSimplificationWorkflowEditing ? (
    <NewStageNavigation {...props} />
  ) : (
    <LegacyStageNavigation {...props} />
  )
}

export default StageNavigation
